@use '@angular/material' as mat;
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~swiper/swiper-bundle.css';
@import './style.css';
@import './assets/css/orange.css';
@import './assets/css/responsive.css';
@import './assets/css/bootstrap-margin-padding.css';
@import './assets/css/hosting.css';
@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@300&display=swap');

@include mat.core();
//#fbb13b
$color-intic-primary: (
    600: #3b85fb,
contrast: (
        600: white,
    )
);
$color-intic-accent:(
    700: #00bf51,
    contrast: (
        700: white,
    )
);
$color-intic-war:(
    500: #fb513b,
    contrast: (
        500: white,
    )
);

$light-app-primary: mat.define-palette($color-intic-primary, 600);
$light-app-accent:  mat.define-palette($color-intic-accent, 700);
$light-app-warn:    mat.define-palette($color-intic-war, 500);

$dark-app-primary:  mat.define-palette(mat.$blue-palette);
$dark-app-accent:  mat.define-palette(mat.$green-palette);
$dark-app-warn:    mat.define-palette(mat.$red-palette);

$light-app-theme: mat.define-light-theme((
    color: (
        primary: $light-app-primary,
        accent:  $light-app-accent,
        warn:    $light-app-warn
    )
));

$dark-app-theme: mat.define-dark-theme((
    color: (
        primary: $dark-app-primary,
        accent:  $dark-app-accent,
        warn:    $dark-app-warn
    )
));
@include mat.all-component-themes($light-app-theme);
.dark-theme {
    @include mat.all-component-themes($dark-app-theme);
    background: #323232;
    color:white;
}

.light-theme {
    @include mat.all-component-themes($light-app-theme);
    background: white;
    color:#212121;
}

* {
    font-family: 'Oxanium',
    "Segoe UI",
    "sans-serif";
}
a:hover{
    text-decoration: none;
}
.mat-tab-link {
    font-family: 'Oxanium',
    "Segoe UI",
    "sans-serif";
    font-size: 16px;
    font-weight: bold;
}

.text-secondary{
    color: #494f55 !important
}

.breadcumb li {
    margin-left: 5px;
}

