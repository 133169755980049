	/*
  	Flaticon icon font: Flaticon
  	Creation date: 10/03/2016 19:43
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("Flaticon.eot");
  src: url("Flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-animals:before { content: "\f100"; }
.flaticon-box:before { content: "\f101"; }
.flaticon-business:before { content: "\f102"; }
.flaticon-circle:before { content: "\f103"; }
.flaticon-clock:before { content: "\f104"; }
.flaticon-clock-1:before { content: "\f105"; }
.flaticon-coins:before { content: "\f106"; }
.flaticon-earth:before { content: "\f107"; }
.flaticon-fruit:before { content: "\f108"; }
.flaticon-gesture:before { content: "\f109"; }
.flaticon-gesture-1:before { content: "\f10a"; }
.flaticon-hand:before { content: "\f10b"; }
.flaticon-hands:before { content: "\f10c"; }
.flaticon-heart:before { content: "\f10d"; }
.flaticon-heart-1:before { content: "\f10e"; }
.flaticon-interface:before { content: "\f10f"; }
.flaticon-interface-1:before { content: "\f110"; }
.flaticon-interface-2:before { content: "\f111"; }
.flaticon-location:before { content: "\f112"; }
.flaticon-medical:before { content: "\f113"; }
.flaticon-money:before { content: "\f114"; }
.flaticon-peace:before { content: "\f115"; }
.flaticon-peace-1:before { content: "\f116"; }
.flaticon-people:before { content: "\f117"; }
.flaticon-people-1:before { content: "\f118"; }
.flaticon-people-2:before { content: "\f119"; }
.flaticon-people-3:before { content: "\f11a"; }
.flaticon-people-4:before { content: "\f11b"; }
.flaticon-pin:before { content: "\f11c"; }
.flaticon-pin-1:before { content: "\f11d"; }
.flaticon-pin-2:before { content: "\f11e"; }
.flaticon-shapes:before { content: "\f11f"; }
.flaticon-shapes-1:before { content: "\f120"; }
.flaticon-shapes-2:before { content: "\f121"; }
.flaticon-shapes-3:before { content: "\f122"; }
.flaticon-social:before { content: "\f123"; }
.flaticon-technology:before { content: "\f124"; }
.flaticon-telephone:before { content: "\f125"; }
.flaticon-time:before { content: "\f126"; }